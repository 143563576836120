import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import AppRoutes from './AppRoutes';
// import { AuthProvider } from './app/AuthProvider';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<AppRoutes />
);

serviceWorker.unregister();
