import React, { useState } from 'react';
import Logo from '../assets/images/lg.svg';
import Profile from '../assets/images/Profile.svg';
import UpdateProfile from '../myprofile';
import { Drawer } from '@mui/material';

const sidebarOverrideCssFn = () => ({
	display: 'none'
});

const contentOverrideCssFn = (defaultStyles: any) => ({
	...defaultStyles,
	display: 'flex',
	marginTop: 0,
	flexDirection: 'column'
});

function Navbar() {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const open = () => {
		setIsDrawerOpen(true);
	};

	const close = () => {
		setIsDrawerOpen(false);
	};

	return (
		<div>
			<nav className='navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row'>
				<div className='navbar-menu-wrapper d-flex align-items-center justify-content-between'>
					<a
						className='navbar-brand brand-logo-mini align-self-center '
						href='!#'
						onClick={(evt) => evt.preventDefault()}>
						{' '}
						<img
							src={Logo}
							alt='logo'
							width='80%'
						/>
					</a>
					<p
						className='nav-account pointer'
						onClick={open}>
						<img
							src={Profile}
							alt='...'
							className='nav-img'
						/>
						My Account
					</p>
				</div>
			</nav>
			<a
				className='dc '
				href='!#'
				onClick={(evt) => evt.preventDefault()}></a>

			{/* <Drawer
				onClose={close}
				open={isDrawerOpen}
				anchor='right'
				sx={{
					Sidebar: {
						cssFn: sidebarOverrideCssFn
					},
					Content: {
						cssFn: contentOverrideCssFn
					}
				}}> */}
			<Drawer
				onClose={close}
				open={isDrawerOpen}
				anchor='right'
				sx={{
					'& .MuiDrawer-paper': contentOverrideCssFn({ boxSizing: 'border-box', width: '700px' })
				}}>
				<UpdateProfile />
			</Drawer>
		</div>
	);
}

export default Navbar;
