import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import request from '../shared/request';
import { toast } from 'react-toastify';
// import PageTitle from "./../components/PageTitle";

function UpdateProfile(props: any) {
	const [userData, setuserData] = useState(null);

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		setValue,
		setError
	} = useForm<{ username:string, password: any; confirmPassword: any; email: string, firstName:any, lastName:any }>();

	const onSubmit = async (data: { password: any; confirmPassword: any; email: string; }) => {
		if (data.password !== data.confirmPassword) {
			setError('password' , { message: "Password doesn't match"});
			return;
		}
		const postResponse = request('/admin/changeStaffPassword', {
			method: 'POST',
			payload: {
				email: data.email.trim(),
				password: data.password
			}
		});
		Promise.all([postResponse]).then(() => {
			toast.success('Profile has been update Successfully!', {
				position: 'top-center'
			});
			setValue('password', '');
			setValue('confirmPassword', '');
		});
	};

	const getCurrentUser = async () => {
		await request('/admin/getCurrentStaffProfile').then((response) => {
			if (response.data) {
				const { username, email } = response.data;
				setuserData(response.data);
				setValue('username', username);
				setValue('email', email);
			}
		});
	};

	useEffect(() => {
		getCurrentUser();
	}, []);

	return (
		<div className='col-lg-12 p-4 grid-margin stretch-card'>
			<div className='card Account'>
				<div className='card-body '>
					<h4>
						<b>My Account</b>
					</h4>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='d-flex mt-4 flex-column search-field'>
							<Form.Label className='font-weight-bolder'>Username</Form.Label>
							<Form.Control
								type='text'
								placeholder='UserName'
								{...register('username', {
									required: true,
									pattern: /^[A-Za-z0-9- ]+$/
								})}
								autoComplete='off'
								className='rounded'
							/>
						</Form.Group>
						{errors?.firstName?.type === 'required' && <p className='text-danger'>First name is required</p>}
						{errors?.firstName?.type === 'pattern' && (
							<p className='text-danger'>Only characters and numbers allowed</p>
						)}
						<Form.Group className='d-flex mt-2 flex-column search-field'>
							<Form.Label className='font-weight-bolder'>Email</Form.Label>
							<Form.Control
								type='email'
								placeholder='Email'
								{...register('email', {
									required: true
								})}
								autoComplete='off'
								className='rounded'
								readOnly
							/>
						</Form.Group>
						{errors?.lastName?.type === 'required' && <p className='text-danger'>Last name is required</p>}
						<Form.Group className='d-flex mt-2 flex-column search-field'>
							<Form.Label className='font-weight-bolder'>New Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Password'
								{...register('password', {
									required: true,
									minLength: 3
								})}
								autoComplete='off'
								className='rounded'
							/>
						</Form.Group>
						{errors?.password?.type === 'required' && <p className='text-danger'>password is required</p>}
						{errors?.password?.type === 'minLength' && <p className='text-danger'>Min 3 characters required</p>}
						{errors?.password && <p className='text-danger'>Password doesn't match</p>}
						<Form.Group className='d-flex mt-2 flex-column search-field'>
							<Form.Label className='font-weight-bolder'>Confirm Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm Password'
								{...register('confirmPassword', {
									required: true,
									minLength: 3
								})}
								autoComplete='off'
								className='rounded'
							/>
						</Form.Group>
						{errors?.confirmPassword?.type === 'required' && (
							<p className='text-danger'>Confirm Password is required</p>
						)}
						{errors?.confirmPassword?.type === 'minLength' && <p className='text-danger'>Min 3 characters required</p>}
						<button
							disabled={!isDirty}
							className='mt-3  btn btn-inline btn-primary btn-md font-weight-medium auth-form-btn'>
							Update
						</button>
					</form>
					<br></br>
				</div>
			</div>
		</div>
	);
}

export default UpdateProfile;
