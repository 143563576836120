
export const useLocalStorage = (key: string, initialValue?: any) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const storedValue = (() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			try {
				// Parse stored json or if none return initialValue
				return item || initialValue;
			} catch (error) {
				return item || initialValue;
			}
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	})();

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: (arg0: any) => any) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save to local storage
			if (typeof window !== 'undefined') {
				console.log(`valueToStore: ${valueToStore}`);
				if (typeof (valueToStore) === 'undefined' || valueToStore == null) {
					console.log('valueToStore null');
					window.localStorage.removeItem(key);
				} else {
					window.localStorage.setItem(key, valueToStore);
				}
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
};
