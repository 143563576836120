import moment from "moment";

export const convertObjectToUrlparams = (data: { [x: string]: any; }) => {
    let returnValue="?";
    if(data)Object.keys(data).forEach(key=>{
        let value = data[key];
        // if(key === "Size") value = PAGE_SIZE;
        if((key && value && value !== "Invalid date") || (key === "searchKeyword" && value && value.length > 2)){
            if((key === "createdFromDate" && moment(value).isValid()) || (key === "createdToDate" && moment(value).isValid())) value = moment(value).format("MM/DD/YYYY")
            returnValue = returnValue + key + '=' + value + '&';
        }
    })
    returnValue = returnValue.slice(0, returnValue.length-1)
    return returnValue
}

export const convertDateFormat = (date: string | number | Date) => {
    const d = new Date(date);
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const uaeDate = new Date(utc + (3600000*4));
    return date ? moment(uaeDate).format('DD/MM/YYYY') : 'NA';
}

export const convertDateTimeFormat = (date: string | number | Date) => {
    const d = new Date(date);
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const uaeDate = new Date(utc + (3600000*4));
    return date ? moment(uaeDate).format('DD/MM/YYYY hh:mm A') : 'NA';
}

export const getUrlSearchParams = (p: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
    const query = new URLSearchParams(p);
    let data: any = {};

    for (let params of query.entries()) {
        data[params[0]] = params[1];
    }
    return data;
}
