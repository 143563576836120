import React, { lazy } from 'react';
import { Route, createHashRouter, createRoutesFromElements, Navigate, RouterProvider } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import App from './app/App';
import Sso from './app/pages/settings/sso';

const Dashboard = lazy(() => import('./app/pages/dashboard'));
const Login = lazy(() => import('./app/login'));
const ForgotPassword = lazy(() => import('./app/login/forgot-password'));
const Mdi = lazy(() => import('./app/icons/Mdi'));
const SocialMedia = lazy(() => import('./app/pages/socialMedia'));
const SMCategory = lazy(() => import('./app/smProfile/components/category'));
const Error404 = lazy(() => import('./app/error-pages/Error404'));
const Error500 = lazy(() => import('./app/error-pages/Error500'));
const Users = lazy(() => import('./app/pages/users'));
const Uuid = lazy(() => import('./app/pages/uuid'));
const Estore = lazy(() => import('./app/pages/settings'));
const Products = lazy(() => import('./app/pages/products'));
const ProductInfo = lazy(() => import('./app/pages/products/product/ProductInfo'));
const Videos = lazy(() => import('./app/tutorialVideos'));
const Country = lazy(() => import('./app/pages/settings/country'));
const State = lazy(() => import('./app/pages/settings/state'));
const City = lazy(() => import('./app/pages/settings/city'));
const Zipcode = lazy(() => import('./app/pages/settings/zipcode'));
const Orders = lazy(() => import('./app/pages/orders'));
const MyProfile = lazy(() => import('./app/myprofile'));
const Enquiry = lazy(() => import('./app/pages/enquiry'));

function AppRoutes() {
	const router = createHashRouter(
		createRoutesFromElements(
			<>
				<Route
					path='/admin'
					element={<App />}>
					{/* <Route
						exact
						path='/'
						element={
							<AuthenticatedRoute>
								<Dashboard />
							</AuthenticatedRoute>
						}
					/> */}
					<Route
						index
						element={
							<AuthenticatedRoute>
								<Dashboard />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='dashboard'
						element={
							<AuthenticatedRoute>
								<Dashboard />
							</AuthenticatedRoute>
						}
					/>

					<Route
						path='smprofile'
						element={
							<AuthenticatedRoute>
								<SocialMedia />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='smcategory'
						element={
							<AuthenticatedRoute>
								<SMCategory />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='users'
						element={
							<AuthenticatedRoute>
								<Users />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='uuid'
						element={
							<AuthenticatedRoute>
								<Uuid />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='myprofile'
						element={
							<AuthenticatedRoute>
								<MyProfile />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='estore'
						element={
							<AuthenticatedRoute>
								<Estore />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='videos'
						element={
							<AuthenticatedRoute>
								<Videos />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='estore/zipcode'
						element={
							<AuthenticatedRoute>
								<Zipcode />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='estore/country'
						element={
							<AuthenticatedRoute>
								<Country />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='estore/state'
						element={
							<AuthenticatedRoute>
								<State />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='estore/city'
						element={
							<AuthenticatedRoute>
								<City />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='orders'
						element={
							<AuthenticatedRoute>
								<Orders />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='products'
						element={
							<AuthenticatedRoute>
								<Products />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='product/:id'
						element={
							<AuthenticatedRoute>
								<ProductInfo />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='enquiry'
						element={
							<AuthenticatedRoute>
								<Enquiry />
							</AuthenticatedRoute>
						}
					/>
					<Route
						path='icons/mdi'
						element={<Mdi />}
					/>
					<Route
						path='login'
						element={<Login />}
					/>
					<Route
						path='forgot'
						element={<ForgotPassword />}
					/>
					<Route
						path='404'
						element={<Error404 />}
					/>
					<Route
						path='500'
						element={<Error500 />}
					/>
					<Route
						path='*'
						element={<Navigate to='/404' />}
					/>
				</Route>
				<Route
					path='/*'
					element={
						<Navigate
							replace
							to='/admin'
						/>
					}
				/>
			</>
		)
	);

	return <RouterProvider router={router} />;
}

export default AppRoutes;
