import React, { useState } from 'react';
import request from './shared/request';
import useAuth from './AuthProvider';

export const AppContext = React.createContext({});

function AppProvider(props: any) {
	const [users, setUsers] = useState([]);
	const [uuidList, setUuidList] = useState([]);
	const [zipcodes, setZipCodes] = useState([]);
	const [products, setProducts] = useState([]);
	const [orders, setOrders] = useState([]);
	const { logout } = useAuth() as any;

	const getAllUsers = async () => {
		try {
			const response = await request('/admin/registeredUserList');
			if (response && response.data) {
				setUsers(response.data);
			}
		} catch (e) {
			if (e === 401 || e === 403) {
				logout();
			}
		}
	};

	const getAllUuid = async (pageno: any, filters: any) => {
		try {
			const response = await request(
				pageno
					? filters
						? `/admin/getUserUuidList?page=${pageno}${filters}`
						: `/admin/getUserUuidList?page=${pageno}`
					: `/admin/getUserUuidList?${filters}`
			);
			if (response && response.data) {
				setUuidList(response.data);
			}
		} catch (e) {
			if (e === 401 || e === 403) {
				logout();
			}
		}
	};

	const getAllZipcodes = async (pageno: any) => {
		try {
			const response = await request(pageno ? `/admin/getZipCodeDetails?page=${pageno}` : '/admin/getZipCodeDetails');
			if (response && response.data) {
				setZipCodes(response.data);
			}
		} catch (e) {
			if (e === 401 || e === 403) {
				logout();
			}
		}
	};

	const getAllProducts = async (pageno: any) => {
		try {
			const response = await request(pageno ? `/admin/getProductTypeList?page=${pageno}` : '/admin/getProductTypeList');
			if (response && response.data) {
				setProducts(response.data);
			}
		} catch (e) {
			if (e === 401 || e === 403) {
				logout();
			}
		}
	};

	const getAllOrders = async (pageno: any) => {
		try {
			const response = await request(pageno ? `/admin/viewAllOrders?page=${pageno}` : '/admin/viewAllOrders');
			if (response && response.data) {
				setOrders(response.data);
			}
		} catch (e) {
			if (e === 401 || e === 403) {
				logout();
			}
		}
	};

	return (
		<AppContext.Provider
			value={{
				users,
				getAllUsers,
				uuidList,
				getAllUuid,
				zipcodes,
				getAllZipcodes,
				products,
				getAllProducts,
				orders,
				getAllOrders
			}}>
			{props.children}
		</AppContext.Provider>
	);
}

const AppConsumer = AppContext.Consumer;

export { AppProvider, AppConsumer };
